import gsap from "gsap";
import gsap2 from "gsap2";
import { TimelineLite } from "gsap2";
import { Expo } from "gsap2";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap2.registerPlugin(TimelineLite);
gsap2.registerPlugin(Expo);

window.gsap = gsap2;
window.TimelineLite = TimelineLite;
window.Expo = Expo;

// Get a reference to your balloon element
const section = {
	landing: document.querySelector("section#landing") ?? null,
	rules: document.querySelector("section#rules") ?? null,
	products: document.querySelector("section#products") ?? null,
	game: document.querySelector("section#game") ?? null,
};

const balloons = {
	landing: {
		red: section.landing?.querySelector(".decoration-balloon-red"),
		yellow: section.landing?.querySelector(".decoration-balloon-yellow"),
	},
	rules: {
		red: section.rules?.querySelector(".decoration-balloon-red"),
		yellow: section.rules?.querySelector(".decoration-balloon-yellow"),
	},
	products: {
		red: section.products?.querySelector(".decoration-balloon-red"),
	},
	game: {
		red: section.game?.querySelector(".decoration-balloon-red"),
		yellow: section.game?.querySelector(".decoration-balloon-yellow"),
	},
};

const options = {
	start: "top 250px",
	end: "50% 250px",
	scrub: 3,
};

const defaults = {
	landing: {
		trigger: section.landing,
		...options,
	},
	rules: {
		trigger: section.rules,
		...options,
		start: "-500px 50%",
	},
	products: {
		trigger: section.products,
		...options,
		start: "top 50%",
		end: "50% 50%",
	},
	game: {
		trigger: section.game,
		...options,
		start: "-250px 250px",
	},
};

// Landing
if (section.landing) {
	gsap.timeline({
		scrollTrigger: defaults.landing,
	}).fromTo(balloons.landing.red, { bottom: "-50%" }, { bottom: "75%" });
	gsap.timeline({
		scrollTrigger: {
			...defaults.landing,
			scrub: 6,
		},
	}).fromTo(balloons.landing.yellow, { bottom: "-75%" }, { bottom: "50%" });
}

// Rules
if (section.rules) {
	gsap.timeline({
		scrollTrigger: defaults.rules,
	}).fromTo(balloons.rules.red, { bottom: "-50%" }, { bottom: "25%" });
	gsap.timeline({
		scrollTrigger: defaults.rules,
	}).fromTo(balloons.rules.yellow, { bottom: "-25%" }, { bottom: "75%" });
}

// Products
if (section.products) {
	gsap.timeline({
		scrollTrigger: defaults.products,
	}).fromTo(balloons.products.red, { bottom: "0%" }, { bottom: "50%" });
}

// Game
if (section.game) {
	gsap.timeline({
		scrollTrigger: defaults.game,
	}).fromTo(balloons.game.red, { bottom: "-75%" }, { bottom: "0%" });
	gsap.timeline({
		scrollTrigger: defaults.game,
	}).fromTo(balloons.game.yellow, { bottom: "-50%" }, { bottom: "50%" });
}
