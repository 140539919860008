const dropdown = document.getElementById('navigation-dropdown');

if (dropdown) {
	dropdown.querySelectorAll('.nav-item').forEach((item) => {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	});
}

const toggleDropdown = () => {
	if (document.querySelector('#navigation .navbar-toggler').getAttribute('aria-expanded') == 'true') {
		document.querySelector('#navigation .navbar-toggler').click();
	}
};