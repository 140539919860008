// get selector .products-container
const productsContainer = document.querySelector('.products-container');

// list all img elements in productsContainer
const products = productsContainer ? productsContainer.querySelectorAll('img') : null;

// if a product is hovered, set all other products to opacity 0.5
if (productsContainer && products) {
	products.forEach(product => {
		product.addEventListener('mouseover', () => {
			products.forEach(product => {
				product.style.opacity = 0.5;
			});
			product.style.opacity = 1;
		});
		product.addEventListener('mouseout', () => {
			products.forEach(product => {
				product.style.opacity = 1;
			});
		});
	});
}