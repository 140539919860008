import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const button = document.querySelector("a#button-application") ?? null;
const section = {
	landing: document.querySelector("section.s-hero") ?? null,
	application: document.querySelector("section.s-form") ?? null,
};

// Landing
if (section.landing) {
	gsap.timeline({
		scrollTrigger: {
			trigger: section.landing,
			start: "bottom 250px",
			end: "bottom 250px",
			scrub: true,
		},
	}).fromTo(button, {
		opacity: "0",
		pointerEvents: "none",
		userSelect: "none",
	},{
		opacity: "1",
		pointerEvents: "auto",
		userSelect: "auto",
	});
}

if (section.application) {
	// Application - Top
	gsap.timeline({
		scrollTrigger: {
			trigger: section.application,
			start: "top 250px",
			end: "top 250px",
			scrub: true,
		},
	}).fromTo(button, {
		opacity: "1",
		pointerEvents: "auto",
		userSelect: "auto",
	},{
		opacity: "0",
		pointerEvents: "none",
		userSelect: "none",
	});

	// Application - Bottom
	gsap.timeline({
		scrollTrigger: {
			trigger: section.application,
			start: "bottom 250px",
			end: "bottom 250px",
			scrub: true,
		},
	}).fromTo(button, {
		opacity: "0",
		pointerEvents: "none",
		userSelect: "none",
	},{
		opacity: "1",
		pointerEvents: "auto",
		userSelect: "auto",
	});
}
