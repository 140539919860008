import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

let sInventiveSlider;

$('.s-inventive__slider .swiper').each(function(){
    const self = this;

    sInventiveSlider = new Swiper($(this)[0], {
        slidesPerView: 'auto',
        loop: true,
        navigation: {
            nextEl: $(this).siblings('.swiper-button-next')[0],
            prevEl: $(this).siblings('.swiper-button-prev')[0]
        },
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

    let sliderStart = false;

    sInventiveSlider.on('slideChange', function (e) {
        if (e.activeIndex > 0) {
            sliderStart = true;
        }
        if ($(self).closest('.s-inventive__slider').hasClass('videos-slider') && sliderStart) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'carnival_ideas',
                    action: 'arrows_click',
                    item: 'video_carousel',
                    label: 'kinder-dzien-dziecka_2024',
                });
            }
        }

        if ($(self).closest('.s-inventive__slider').hasClass('pdf-slider') && sliderStart) {
            if (e.activeIndex > 0) {
                sliderStart = true;
            }
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'carnival_ideas',
                    action: 'arrows_click',
                    item: 'pdf_instructions_carousel',
                    label: 'kinder-dzien-dziecka_2024',
                });
            }
        }
    });
});