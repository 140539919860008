import { insertAfter } from "./form";
const formGroupFiles = document.querySelectorAll('.form-group-file');
formGroupFiles.forEach((file) => {
	const fileInput = file.querySelector('input[type=file]');
	const filePreview = file.querySelector('img');
	const maxSizeMB = 10
	const errorMessage = `Plik jest większy niż ${maxSizeMB}MB`
	fileInput.addEventListener('click', (event) => {
		event.target.value = null;
		filePreview.src = filePreview.dataset.src;
	})
	fileInput.addEventListener('change', (event) => {
		let file = event.target.files[0];
		if (file) {
			const invalidFeedbackSize = fileInput.parentNode.querySelector('.invalid-feedback-size') ?? null
			invalidFeedbackSize ? invalidFeedbackSize.parentNode.removeChild(invalidFeedbackSize) : null
			if (file.size  > maxSizeMB * 1024 * 1024) {
				insertAfter(fileInput, errorMessage, {
					tag: 'div',
					classList: ['invalid-feedback', 'invalid-feedback-size', 'd-block']
				});
				fileInput.value = null;
				return
			}
			const reader = new FileReader();
			reader.onload = (event) => {
				filePreview.src = event.target.result;

				if (typeof dataLayer !== 'undefined') {
					dataLayer.push({
						event: 'userAction',
						category: 'form',
						action: 'recipe_sent',
						label: 'kids_talents_2023'
					});
				}
			};
			reader.readAsDataURL(file);
		}
	})
});