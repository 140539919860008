$(document).ready(function() {
	let counter = $('.counter-group');
	let daysID = counter.find('#counter-days');
	let days = daysID.data('days');
	let hoursID = counter.find('#counter-hours');
	let hours = hoursID.data('hours');
	let minutesID = counter.find('#counter-minutes');
	let minutes = minutesID.data('minutes');
	let secondsID = counter.find('#counter-seconds');
	let seconds = secondsID.data('seconds');
	if (counter.length) {
		let interval = setInterval(function() {
			if (seconds == 0) {
				if (minutes == 0) {
					if (hours == 0) {
						if (days == 0) {
							clearInterval(interval);
							return;
						}
						days--;
						hours = 24;
					}
					hours--;
					minutes = 60;
				}
				minutes--;
				seconds = 60;
			}
			seconds--;
			daysID.text(days);
			hoursID.text(hours);
			minutesID.text(minutes);
			secondsID.text(seconds);
		}, 1000);
	}
});