import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

let sProductsSlider;

$('.s-products__image-slider .swiper').each(function(){
    const self = this;

    sProductsSlider = new Swiper($(this)[0], {
        slidesPerView: 'auto',
        loop: true,
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

    // sProductsSlider.on('slideChange', function (e) {
    //     if (e.realIndex === 2) {
    //         $('.s-products__decor-1').addClass('d-none');
    //         $('.s-products__decor-4').removeClass('d-none');
    //     } else {
    //         $('.s-products__decor-4').addClass('d-none');
    //         $('.s-products__decor-1').removeClass('d-none');
    //     }
    // });

    // let sliderStart = false;
    //
    // sProductsSlider.on('slideChange', function (e) {
    //     if (e.activeIndex > 0) {
    //         sliderStart = true;
    //     }
    //     if ($(self).closest('.s-inventive__slider').hasClass('videos-slider') && sliderStart) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'carnival_ideas',
    //                 action: 'arrows_click',
    //                 item: 'video_carousel',
    //                 label: 'kinder-karnawal_2024',
    //             });
    //         }
    //     }
    //
    // });
});
